@import "../../style/common.scss";

.tile {
    margin-top: 1rem;
    margin-left: -1rem;
    margin-right: -1rem;

    .tile-content {
        padding: 1rem;
    }
    background-color: #ffffff;

    &.dark {
        background-color: #f4f4f4;
    }

    &.clickable {
        &:hover {
            background-color: #d8d8d8;
        }
    }

    @media only screen and (min-width: $brk-tablet) { 
        margin-right: 0rem;
    }
    @media only screen and (min-width: $brk-tablet) { 
        &.ratio1x1, &.ratio2x1, &.ratio4x3, &.ratio16x9 {
            height: 0px;
            overflow: hidden;
            position: relative;
            .tile-content {
                position: absolute;
                top: 0rem;
                left: 0rem;
                width: calc(100% - 0rem);
                height: calc(100% - 0rem);
            }
        }

        &.ratio1x1 {
            padding-top: 100%;
        }

        &.ratio2x1 {
            padding-top: 50%;
        }

        &.ratio4x3 {
            padding-top: 75%;
        }
        &.ratio16x9 {
            padding-top: 56.25%;
        }
    }

    .note {
        position: absolute;
        @media only screen and (min-width: $brk-mob-wide) { 
            bottom: 1rem;
            right: 1rem;
        }
        @media only screen and (min-width: $brk-tablet) { 
            bottom: 0rem;
            right: 0rem;
        }
    }
}
.homeroomLabel {
    .MuiTypography-body1, .MuiTypography-body2 {
        font-size: .8rem;
    }
    .MuiAvatar-root {
        height: 2rem;
        width: 2rem;
        font-size: 1rem;
    }
    .MuiListItemAvatar-root {
        min-width: 3rem;
    }
    .MuiAvatar-colorDefault {
        color: #111111;
    }
}

.homeroomLabelLink {
    border: solid #3f51b5 1px;   
    color: #3f51b5;

    p {
        color: #3f51b5;
    }

    .MuiAvatar-colorDefault {
        background-color: #3f51b5;
    }

    &:hover {
        background-color: #eee
    }
}

.homeroomLabelEdit {
    .MuiListItemText-root {
        margin-right: 1rem;
        flex: inherit;
    }
}
@use '@carbon/type';

.fields {
    @include type.type-style('body-02');
    div + div {
        margin-top: .5rem;
    }
    strong {
        @include type.type-style('heading-01');
    }
}
main.newHomeroom {
    p {
        max-width: 640px;
    }

    a {
        margin-top: 16px;
    }

    .gradeInfo {
        label {
            margin-left: 0px;
            margin-top: 8px;
            margin-right: 8px;
        }
    }

    .nhrSchool {
        min-width: 300px;
    }

    ul {
        margin-block-start: .5rem;
        margin-block-end: 0rem;
        padding-inline-start: 1.25rem;
    }

    .link {
        color: #3f51b5;
        text-decoration: underline;
        cursor: pointer;
    }

    .hrpTable {
        .MuiAlert-standardInfo {
            background-color: white;
        }

        .MuiAlert-message {
            width: 100%;
        }
    }
}
.signups {
    // width: 100%;

    // .homeClass {
    //     font-size: .8rem;
    // }

    // .wishItem {
    //     text-indent: -.5rem;
    //     padding-left: .5rem;
    //     margin-top: .5rem;
    // }
    // .MuiCheckbox-root {
    //     margin-left: -11px;
    //     padding-left: .5rem;
    //     padding-right: .25rem;
    //     padding-top: 0rem;
    //     padding-bottom: 0rem;
    // }

    .switchStatus {
        font-size: .64rem;
        margin-left: .25rem;
    }
}
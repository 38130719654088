@use '@carbon/styles/scss/breakpoint';

.ptHomeRoot {
    background-color: rgba(0,0,0,1);
    background-blend-mode:overlay;
    min-height: calc(100vh - 10.5rem);
    min-width: 100vw;
    max-width: 100vw;
    background: url("../../../../../public/images/lights.png");
    background-size: cover;

    @media only screen and (max-width: 671px) {
        min-height: calc(100vh - 15rem);
    }
}

.ptHomeFooter {
    background-color: hsl(181, 55%, 60%);
    height: 4.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

main.home {
    .formHeading {
        font-size: 2.5rem;
        padding-bottom: 1.5rem;

        span {
            font-weight: 700;
        }
    }

    .calbal { 
        @media screen and (min-height: 38rem) {
            margin-bottom: 2rem;
        }
        img {
            display: none;
            @media screen and (min-height: 45rem) {
                // margin-bottom: 2rem;
                display: inline;
                img { height: 100px }
            }
        }
    }
}
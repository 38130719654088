
.mdContent {
    h2 {
        margin-top: 1.5rem;
        display: block;
    }
    h3 {
        margin-top: 1.25rem;
        display: block;
    }
}
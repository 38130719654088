@use '@carbon/type';
// .partyPlaces {
//     margin: 0px;
//     padding-bottom: 20px;
//     padding-top: 10px;
//     margin-top: -2px;
    
//     .inputs {
//         height: 70px;
//         border-bottom: solid #ccc 1px;
//         background-color: white;
//     }
// }

.Camps {
    h2 {
        @include type.type-style('heading-03');
    }

    .campTitle {
        font-weight: 800;
        opacity: .8;
    }

    .sep {
        color: #999;
    }

    .sessionTitle {
        @include type.type-style('body-compact-01');
    }

    .sessionInfo {
        @include type.type-style('helper-text-01');
        color: #333;
    }
}
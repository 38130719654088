@import "../../style/common.scss";

header.mainHeader {
    height: 3rem;
    z-index: 10;
    line-height: 3rem;

    font-family: 'Open Sans', sans-serif;
    font-size: 1rem;
    padding-left: 1rem;

    .name {
        font-family: 'Open Sans', sans-serif;
        font-size: 1rem;
        font-weight: 600;
        visibility: hidden;
        @media only screen and (min-width: 410px) {
            visibility: visible;
        }
    }

    .headLogin {
        text-align:right;
        font-size: 14px;
        a {
            font-weight: 300;
        }
        svg {
            height: 14px;
            vertical-align: middle;
            margin-left: "8px"
        }
    }

    // @media only screen and (min-width: $brk-tablet) {
    //     height: 4rem;
    //     line-height: 4rem;

    //     font-size: 1.25rem;
    //     .headLogin {
    //         font-size: 1rem;
    //     }
    // }
}

@use '@carbon/type';

.TakeIt {
    h2 {
        @include type.type-style('heading-03');
        font-weight: 800;
    }

    .resInfo {
        @include type.type-style('body-compact-02');
    }
}
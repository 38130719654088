.partyPlaces {
    min-height: calc(100vh - 10.5rem);
//     margin: 0px;
//     padding-bottom: 20px;
//     padding-top: 10px;
//     margin-top: -2px;
    
//     .inputs {
//         height: 70px;
//         border-bottom: solid #ccc 1px;
//         background-color: white;
//     }
}
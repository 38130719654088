.signupCalendar {
    border-spacing: 0px;
    td.calDay {
        border: solid transparent 2px; 
        min-width: 3.5rem;
        text-align: right;
        padding: .5rem;
    }

    td.calDay.inactive {
        background-color: #f0f0f0;
    }

    td.calDay.unselected {
        cursor: pointer;
    }

    td.calDay.selected {
        cursor: pointer;
        background-color: #2F5043;
        color: white;
    }

    tbody:focus {
        outline-width: 0px;
        td.curFocus {
            box-sizing: border-box;
            outline: solid blue 2px;
            outline: -webkit-focus-ring-color auto 2px;
        }
    }
}
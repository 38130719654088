@use '@carbon/type';
@use '@carbon/themes';

.bookingTile {
    p.note {
        @include type.type-style('helper-text-02');
    }
}

.invalidTime {
    .cds--label {
        color: themes.$text-error;
        font-weight: 400;
    }
}

.invalidPackage, .invalidGuests {
    .cds--label {
        color: themes.$text-error;
        font-weight: 400;
    }
}
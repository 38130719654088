@use '@carbon/styles/scss/breakpoint';

.background {
    border: 0;
    opacity: .33;
    width: 100%;
    min-width: 950px;
    position: absolute;
    top: 0;
}

main.home {
//     margin: 0;
//     background-color: #000;
//     height: 450px;
//     width: 100%;
    z-index: 0;
    position: relative;
    // overflow: hidden;

//     @media screen and (max-width: 639px) {
//         height: 550px;
//     }

    > div > div {
        position: relative;
        top: 0;
        z-index: 1;
    }

    .formHeading {
        font-size: 2.5rem;
        padding-bottom: 1.5rem;
//         display: block;

        span {
            font-weight: 700;
        }
    }

//     form {
//         margin-left: auto;
//         margin-right: auto;
//         padding-top: 50px;
//         width: 100%;
//         max-width: 635px;
//         color: #fff;

//         .formFields {
//             padding: 16px;
//             background-color: white;
//             color: black;
//             border-radius: 3px;

//             .formField {
//                 margin-top: 16px;
//                 margin-bottom: 16px;
//             }
//         }
//     }

//     @media screen and (max-width: 600px) {
//         table > tbody > tr > td img {
//             display: none;
//         }
//     }

    .calbal { 
        display: none;
        @include breakpoint.breakpoint('md') {
            display: block;
            img { height: 100px }
        }
        @include breakpoint.breakpoint('lg') {
            img { height: 150px }
        }
    }
}
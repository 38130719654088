@import "../../style/common.scss";

div#root {
    main.home {
        h2 {
            font-size: 1.25rem;
            font-weight: 400;
        }
        font-size: 1.25rem;

        .blackboard {
            background-color: #022d1e;
            color: #f4f4f0;
            font-size: 4rem;
            font-family: 'Indie Flower', cursive;
            padding: 4rem 0rem;
            background-image: url("../../images/blackboard.png");
        }
        .introButton {
            position: absolute;
            left: 0;
            +.introButton {
                left: calc(50% - 3.5rem);
                +.introButton {
                    left: calc(100% - 7rem);
                }
            }
            background-color: #F4F4F4;
            border: solid #CCC 2px;
            border-radius: 10rem;
            height: 7rem;
            width: 7rem;
            > div {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-align: center;
                width: 7rem;
            }
            &:hover {
                cursor: pointer;
                background-color: #CCC;
            }
        }
        div:first-of-type > .introButton {
            margin-left: 0px;
        }
        div:last-of-type > .introButton {
            margin-right: 0px;
        }
    }

    .clubBooster {
        main.home {
            .blackboard {
                background-image: url("../../images/blackboard2.png");
                .homeText {
                    font-size: 3rem;
                    @media only screen and (min-width: $brk-mob-wide) { 
                        font-size: 4rem;
                    }                    
                }
            }
        }
    }
}
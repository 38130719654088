@import "../../style/common.scss";

footer {
    position: absolute;
    width: 100%;
    background-color: #F4F4F4;

    bottom: -5rem;
    height: 4rem;

    > div {
        padding: .5rem 0rem;
        font-size: .8rem;
        font-weight: 300;
    }
}

.hrpcb {
    main {
        h1 {
            margin-block-start: 3rem;
            margin-block-end: 2rem;
        }

        h2 {
            margin-block-start: 2rem;
            margin-block-end: 1rem;
        }
    }

    div:has(nav[aria-label=Breadcrumb])~main h1 {
        margin-block-start: 1.5rem;
        margin-block-end: 1rem;
    }
}
// @use '@carbon/react/scss/theme' 
@use '@carbon/react';

@use '@carbon/themes/scss/themes' as *;
@use '@carbon/themes';
@use '@carbon/themes/scss/utilities';
@use '@carbon/layout';
@use '@carbon/type';

$pt-brand: (
    brand: hsl(181, 55%, 60%),
    branddark: hsl(181, 55%, 40%),
    background: hsl(181, 55%, 60%)
  ) !default;
$pt-brand: utilities.merge(
    $white,
    $pt-brand,
    layout.$spacing,
    layout.$fluid-spacing,
    type.$tokens
  );
  
.cds--pt-brand {
    @include themes.theme($pt-brand);
    background-color: themes.$background;
    color: themes.$text-primary;
}

$pt-white: (
    brand: hsl(181, 55%, 60%),
    branddark: hsl(181, 55%, 40%)
) !default;
$pt-white: utilities.merge(
    $white,
  $pt-white,
  layout.$spacing,
  layout.$fluid-spacing,
  type.$tokens
);

:root, .cds--pt-white {
    @include themes.theme($pt-white);
    background-color: themes.$background;
    color: themes.$text-primary;
    --cds-button-primary: hsl(181, 55%, 60%);
    --cds-button-primary-hover: hsl(181, 55%, 40%);
    --cds-button-tertiary: hsl(181, 55%, 40%);
    --cds-button-tertiary-hover: hsl(181, 55%, 40%);
    --cds-text-on-color: #161616;
}
  
$pt-g10: (
    brand: hsl(181, 55%, 60%),
    branddark: hsl(181, 55%, 40%)
) !default;
$pt-brand: utilities.merge(
    $white,
    $pt-white,
    layout.$spacing,
    layout.$fluid-spacing,
    type.$tokens
);

:root, .cds--pt-g10 {
    @include themes.theme($pt-g10);
    background-color: themes.$background;
    color: themes.$text-primary;
    --cds-background-brand: hsl(181, 55%, 60%);
    --cds-button-primary: hsl(181, 55%, 60%);
    --cds-button-primary-hover: hsl(181, 55%, 40%);
    --cds-button-tertiary: hsl(181, 55%, 40%);
    --cds-button-tertiary-hover: hsl(181, 55%, 40%);
    .cds--btn--primary {
        --cds-text-on-color: #161616;
    }
    .cds--btn--secondary {
        --cds-text-on-color: #ffffff;
    }
    .cds--batch-summary {
        --cds-text-on-color: #161616;
    }
}

$pt-g90: (
    brand: hsl(181, 55%, 60%),
    branddark: hsl(181, 55%, 40%)
) !default;
$pt-brand: utilities.merge(
    $white,
    $pt-white,
    layout.$spacing,
    layout.$fluid-spacing,
    type.$tokens
);

.cds--pt-c90 {
    @include themes.theme($pt-g90);
    background-color: themes.$background;
    color: themes.$text-primary;
}

$pt-g100: (
    brand: hsl(181, 55%, 60%),
    branddark: hsl(181, 55%, 40%)
) !default;
$pt-brand: utilities.merge(
    $white,
    $pt-white,
    layout.$spacing,
    layout.$fluid-spacing,
    type.$tokens
);
  
.cds--pt-g100 {
    @include themes.theme($g100);
    background-color: themes.$background;
    color: themes.$text-primary;
}

.brand {
    color: var(--cds-brand)
}

.branddark {
    color: var(--cds-branddark)
}
html, body, #root {
  background-color: #F4F4F4;
  margin: 0;
  height: 100%;
  font-family: Roboto,sans-serif;
}

div#root {
    min-height: 100%;
}

div#root .MuiButton-root {
    text-transform: none;
}

.clean {
    text-decoration: none;
}

h1 {
    @include type.type-style('heading-05');
    margin-top: 2rem;
    margin-bottom: 1rem;
}

p {
    margin-top: 1rem;
}
p.cds--batch-summary__para {
    margin-top: 0rem;
}

.cds--file__selected-file p.cds--file-filename {
    margin-top: 0rem;
}

h2 {
    @include type.type-style('heading-04');
}

.bodyText {
    @include type.type-style('body-02');
}

@use '@carbon/themes';
@use '@carbon/layout';
@use '@carbon/type';

.LocSchedTable {
    overflow: scroll;
    max-height: calc(100vh - 25rem);
    max-width: calc(100vw - 4rem);
    margin-bottom: 1rem;
    thead {
        position: sticky;
        top: 0;
    }
    th, td {
        padding: layout.$spacing-05 layout.$spacing-05 layout.$spacing-05 layout.$spacing-05;
        // background-color: themes.$layer;
    }

    th {
        @include type.type-style('heading-compact-01');
        color: var(--cds-text-primary,#161616);
        background-color: var(--cds-layer-accent);
        border-top: 1px solid #c6c6c6;
        border-bottom: 1px solid #c6c6c6;

        &.areaStart {
            border-left: 1px solid #c6c6c6;
        }

        &.areaEnd {
            border-right: 1px solid #c6c6c6;
        }
    }

    td {
        @include type.type-style('body-compact-01');
        -webkit-border-after: 1px solid var(--cds-border-subtle);
        border-block-end: 1px solid var(--cds-border-subtle);
        -webkit-border-before: 1px solid var(--cds-layer);
        border-block-start: 1px solid var(--cds-layer);
        color: var(--cds-text-secondary,#525252);
        background-color: white;
        -webkit-padding-end: 1rem;
        padding-inline-end: 1rem;
        -webkit-padding-start: 1rem;
        padding-inline-start: 1rem;

        &.areaStart {
            border-left: 1px solid #c6c6c6;
        }

        &.areaEnd {
            border-right: 1px solid #c6c6c6;
        }
        &.res {
            > * {
                color: var(--cds-text-primary,#161616);
                text-decoration: none;
                display: block;
                padding: 1rem;
                // border-radius: 1rem;
                background-color: #c6c6c6;
                // border: solid var(--cds-branddark) 1px;
                height: 100%;

                > .actions {
                    margin: -1rem;
                    text-align: right;
                }
            }
        }
        &.resSelected {
            > * {
                background-color: var(--cds-brand);
                border: solid var(--cds-branddark) 1px;
            }
        }
    }
}
.hrCalFooter {
    padding-top: 1rem;
    font-size: .8rem;

    ul {
        padding-left: 1rem;
    }

    .calLink {
        // display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: calc(100% - 30px);
    }
}
.fc {
    table {
        font-size: .8em;
    }
    .fc-list-day-cushion {
        padding: 8px 1rem;
        font-size: .64rem;
    }

    .fc-list-table td {
        padding: .5rem;
        background-color: white;
    }
    .fc-list-table td:first-of-type {
        padding: .5rem .5rem .5rem 1rem;
        white-space: inherit;
    }

    .fc-list-table td:last-of-type {
        padding: .5rem 1rem .5rem .5rem;
    }

    .fc-toolbar-title {
        font-size: .8rem;
        font-weight: normal;
        margin: 0rem 1rem;
    }

    .fc-button {
        font-size: .8rem;
    }

    button.fc-button {
        background-color: #2F5043;
        &:hover {
            background-color: #4d8062;
        }
    }
    button[disabled].fc-button {
        background-color: #bdbdbd;
        border-color: #999999;
    }
}

div[role=dialog] .fc {
    table {
        font-size: .9rem;
    }
    
    .fc-toolbar-title {
        font-size: 1rem;
    }
}
.hrpTable {
    .MuiToolbar-root {
        background-color: #f0f0f0;
        border-bottom: solid #eee 1px;
    }
    
    .MuiTablePagination-root {
        background-color: #f0f0f0;
    }

    thead th {
        font-weight: 600;
    }

    .MuiToolbar-gutters {
        padding-left: 16px;
        padding-right: 16px;
    }

    .MuiTablePagination-root {
        > div {
            min-height: 0px;
        }
    }
}
@use '@carbon/type';

.ptResults {
    .resultTitle {
        @include type.type-style('heading-02');
        color: var(--cds-branddark);
    }
    .cds--tile--clickable:hover {
        background-color: white;
        .resultTitle {
            text-decoration: underline;
        }
    }
}

.timeGroup {
    display: inline-block;
    position: relative;
    button {
        border-width: 0;
        background-color: var(--cds-button-secondary);
        color: var(--cds-text-on-color);
        padding: .5rem;
        margin: .25rem;
        border-radius: 1rem;
        &:hover {
            background-color: var(--cds-button-secondary-hover);
        }
    }
    button:first-of-type {
        margin-left: 0rem;
    }
}

.price {
    @include type.type-style('heading-02');
}
.themeDefault {
    .login {
        text-align:center;
        // color: #009887;
        @media only screen and (max-width: 700px) {
            padding: 16px;
        }
        strong {
            font-weight: 400;
        }

        > div {
            max-width: 400px;
            margin-left: auto;
            margin-right: auto;

            > div, hr {
                margin-top: 16px;
                margin-bottom: 16px;
            }

            hr {
                position: relative;
                top: 14px;
            }
            hr + div {
                position: relative;
                top: -14px;
            }
        }

        .pwdReqts {
            text-align: left;
            margin-top: .5rem;
            font-size: .8em;
            color: #333;
        }

        .pwdError {
            text-align: left;
            margin-top: 1.5rem;
            font-size: .8em;
            color: #f44336;
        }
    }
}
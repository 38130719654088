@use '@carbon/type';

.PartyPlace {
    h2 {
        @include type.type-style('heading-02');
        display:block;
        margin-top: 1rem;
    }

    p {
        margin-top: .5rem;
    }
}

.timeGroup {
    display: inline-block;
    position: relative;
    button {
        border-width: 0;
        background-color: white;
        border: solid var(--cds-button-secondary) 1px;
        color: var(--cds-text);
        padding: .5rem;
        margin: .25rem;
        border-radius: 1rem;
        &:hover {
            background-color: #ccc;
        }
        &.selected {
            border: solid var(--cds-branddark) 1px;
            background-color: var(--cds-button-primary);
            color: var(--cds-text-primary);     
            &:hover {
                background-color: var(--cds-button-primary-hover);
            }
        }
    }
    button:first-of-type {
        margin-left: 0rem;
    }
}
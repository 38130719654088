@use '@carbon/styles/scss/breakpoint';
@use '@carbon/themes';

.homeNavBar {
    .spacer {
        color: #ccc;
        margin-left: 5px;
        margin-right: 5px;
    }
    
    a[href] {
        text-decoration: none;
        color: themes.$text-primary;
    }
    
    a[href]:hover {
        text-decoration: none;
        color: themes.$link-primary-hover;
    }

    .name {
        font-family: 'Bubblegum Sans';
        font-size: 23px;

        a {
            color: themes.$text-primary;
            text-decoration: none;
        }
    }
    
    .rightNav {
        @include breakpoint.breakpoint('md') {
            text-align: right;
        }
    }
}
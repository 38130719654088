// @use '@carbon/react/scss/theme' 
@use '@carbon/react';

@use '@carbon/themes/scss/themes' as *;
@use '@carbon/themes';
@use '@carbon/themes/scss/utilities';
@use '@carbon/layout';
@use '@carbon/type';

$ct-brand: (
    brand: hsl(209, 100%, 60%),
    branddark: hsl(209, 100%, 40%),
    background: hsl(209, 100%, 60%)
) !default;
$ct-brand: utilities.merge(
    $white,
    $ct-brand,
    layout.$spacing,
    layout.$fluid-spacing,
    type.$tokens
);

.cds--ct-brand {
    @include themes.theme($ct-brand);
    background-color: themes.$background;
    color: themes.$text-primary;
}

$ct-white: (
    brand: hsl(209, 100%, 60%),
    branddark: hsl(209, 100%, 40%)
) !default;
$ct-brand: utilities.merge(
    $white,
    $ct-white,
    layout.$spacing,
    layout.$fluid-spacing,
    type.$tokens
);

.cds--ct-white {
    @include themes.theme($ct-white);
    background-color: themes.$background;
    color: themes.$text-primary;
    --cds-button-primary: hsl(209, 100%, 60%);
    --cds-button-primary-hover: hsl(209, 100%, 40%);
    --cds-text-on-color: #161616;
}

$ct-g10: (
    brand: hsl(209, 100%, 60%),
    branddark: hsl(209, 100%, 40%)
) !default;
$ct-brand: utilities.merge(
    $white,
    $ct-white,
    layout.$spacing,
    layout.$fluid-spacing,
    type.$tokens
);

:root, .cds--ct-g10 {
    @include themes.theme($ct-g10);
    background-color: themes.$background;
    color: themes.$text-primary;
    --cds-button-primary: hsl(209, 100%, 60%);
    --cds-button-primary-hover: hsl(209, 100%, 40%);
    .cds--btn--primary {
        --cds-text-on-color: #161616;
    }
    .cds--btn--secondary {
        --cds-text-on-color: #ffffff;
    }
}

$ct-g90: (
    brand: hsl(209, 100%, 60%),
    branddark: hsl(209, 100%, 40%)
) !default;
$ct-brand: utilities.merge(
    $white,
    $ct-white,
    layout.$spacing,
    layout.$fluid-spacing,
    type.$tokens
);

.cds--ct-c90 {
    @include themes.theme($ct-g90);
    background-color: themes.$background;
    color: themes.$text-primary;
}

$ct-g100: (
    brand: hsl(209, 100%, 60%),
    branddark: hsl(209, 100%, 40%)
) !default;
$ct-brand: utilities.merge(
    $white,
    $ct-white,
    layout.$spacing,
    layout.$fluid-spacing,
    type.$tokens
);
  
.cds--ct-g100 {
    @include themes.theme($g100);
    background-color: themes.$background;
    color: themes.$text-primary;
}

.brand {
    color: var(--cds-brand)
}

.branddark {
    color: var(--cds-branddark)
}
html, body, #root {
    background-color: #F4F4F4;
  margin: 0;
  height: 100%;
  font-family: Roboto,sans-serif;
}

div#root {
    min-height: 100%;
}

div#root .MuiButton-root {
    text-transform: none;
}

.clean {
    text-decoration: none;
}

h1 {
    @include type.type-style('heading-05');
}

h2 {
    @include type.type-style('heading-04');
}

.bodyText {
    @include type.type-style('body-02');
}
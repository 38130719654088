$brk-mob-wide: 576px;
$brk-tablet: 768px;
$brk-desk: 992px;
$brk-desk-large: 1200px;
$hrp-brand: #ffcc00;
$hrp-brand-text: #333333;
$club-brand: #153243;
$club-brand-text: #f4f4f4;

.cleanLink {
    text-decoration: none;
}

.regLink, .regLink:visited {
    color: #054A91;
}

html {
    height: 100%; 
}
html, body {
    font-size: 16px;
    font-family: Montserrat, sans-serif;
    margin: 0px;
    min-height: 100%;
}
.deskHome {
    background-color: #e8ebed;
    .tile {
        border: solid #999 1px;
    }
    .sectionTitle {
        margin: -1rem -1rem 1rem -1rem;
        padding: 1rem 1rem .5rem 1rem;
        background-color: $hrp-brand;
        color: $hrp-brand-text;
        svg {
            fill: $hrp-brand-text
        }
        button {
            margin-top: -.8rem;
            margin-bottom: -.8rem;
        }
    }
}
.clubBooster {
    .deskHome {
        .sectionTitle {
            background-color: $club-brand;
            color: $club-brand-text;
            svg {
                fill: $club-brand-text
            }
        }
    }
}

h2 {
    font-weight: 300;
    font-size: 1.25rem;
}
.mobileHome {
    h2 {
        font-weight: 400;
    }

    .MuiAccordionSummary-content {
        margin: 0.25rem 0rem 0rem 0rem;
    }
}

.hrpRow {
    display: flex;
    flex-wrap: wrap;
    max-width: 1800px;

    *[class*="hrpCol-"] {
        padding: 0rem 1rem;
        flex: 25%;
        max-width: calc(25% - 0rem);
        @media only screen and (min-width: $brk-tablet) {
            flex: 12.5%;
            max-width: calc(12.5% - 0rem);
        }
        @media only screen and (min-width: $brk-desk) { 
            flex: 6.25%;
            max-width: calc(6.25% - 0rem);
        }
        @media only screen and (min-width: $brk-desk-large) { 
            flex: 3.125%;
            max-width: calc(3.125% - 0rem);
        }
    }

    .hrpCol-skip-1-mob { margin-left: 25%; }
    .hrpCol-skip-2-mob { margin-left: 50%; }
    .hrpCol-skip-3-mob { margin-left: 75%; }
    
    .hrpCol-0-mob {
        display: none;
        flex: 0%; 
        max-width: calc(0% - 0rem);
    }
    .hrpCol-1-mob { 
        flex: 25%; 
        max-width: calc(25% - 0rem);
    }
    .hrpCol-2-mob {
        flex: 50%;
        max-width: calc(50% - 0rem);
    }
    .hrpCol-3-mob {
        flex: 75%;
        max-width: calc(75% - 0rem);
    }
    .hrpCol-4-mob {
        flex: 100%;
        max-width: calc(100% - 0rem);
    }

    @media only screen and (min-width: $brk-tablet) {
        .hrpCol-skip-1-tab { margin-left: 12.5%; }
        .hrpCol-skip-2-tab { margin-left: 25%; }
        .hrpCol-skip-3-tab { margin-left: 37.5%; }
        .hrpCol-skip-4-tab { margin-left: 50%; }
        .hrpCol-skip-5-tab { margin-left: 62.5%; }
        .hrpCol-skip-6-tab { margin-left: 75%; }
        .hrpCol-skip-7-tab { margin-left: 87.5%; }

        .hrpCol-0-tab {
            display: none;
            flex: 0%;
            max-width: calc(0% - 0rem);
        }
        .hrpCol-1-tab {
            flex: 12.5%;
            max-width: calc(12.5% - 0rem);
        }
        .hrpCol-2-tab {
            flex: 25%;
            max-width: calc(25% - 0rem);
        }
        .hrpCol-3-tab {
            flex: 37.5%;
            max-width: calc(37.5% - 0rem);
        }
        .hrpCol-4-tab {
            flex: 50%;
            max-width: calc(50% - 0rem);
        }
        .hrpCol-5-tab {
            flex: 62.5%;
            max-width: calc(62.5% - 0rem);
        }
        .hrpCol-6-tab {
            display: block;
            flex: 75%;
            max-width: calc(75% - 0rem);
        }
        .hrpCol-7-tab {
            flex: 87.5%;
            max-width: calc(87.5% - 0rem);
        }
        .hrpCol-8-tab {
            flex: 100%;
            max-width: calc(100% - 0rem);
        }
    }

    @media only screen and (min-width: $brk-desk) {
        .hrpCol-skip-1-desk { margin-left: 6.25%; }
        .hrpCol-skip-2-desk { margin-left: 12.5%; }
        .hrpCol-skip-3-desk { margin-left: 18.75%; }
        .hrpCol-skip-4-desk { margin-left: 25%; }
        .hrpCol-skip-5-desk { margin-left: 31.25%; }
        .hrpCol-skip-6-desk { margin-left: 37.5%; }
        .hrpCol-skip-7-desk { margin-left: 43.75%; }
        .hrpCol-skip-8-desk { margin-left: 50%; }
        .hrpCol-skip-9-desk { margin-left: 56.25%; }
        .hrpCol-skip-10-desk { margin-left: 62.5%; }
        .hrpCol-skip-11-desk { margin-left: 68.75%; }
        .hrpCol-skip-12-desk { margin-left: 75%; }
        .hrpCol-skip-13-desk { margin-left: 81.25%; }
        .hrpCol-skip-14-desk { margin-left: 87.5%; }
        .hrpCol-skip-15-desk { margin-left: 93.75%; }

        .hrpCol-0-desk {
            display: none;
            flex: 0%;
            max-width: calc(0% - 0rem);
        }
        .hrpCol-1-desk {
            flex: 6.25%;
            max-width: calc(6.25% - 0rem);
        }
        .hrpCol-2-desk {
            flex: 12.5%;
            max-width: calc(12.5% - 0rem);
        }
        .hrpCol-3-desk {
            flex: 18.75%;
            max-width: calc(18.75% - 0rem);
        }
        .hrpCol-4-desk {
            flex: 25%;
            max-width: calc(25% - 0rem);
        }
        .hrpCol-5-desk {
            flex: 31.25%;
            max-width: calc(31.25% - 0rem);
        }
        .hrpCol-6-desk {
            flex: 37.5%;
            max-width: calc(37.5% - 0rem);
        }
        .hrpCol-7-desk {
            flex: 43.75%;
            max-width: calc(43.75% - 0rem);
        }
        .hrpCol-8-desk {
            flex: 50%;
            max-width: calc(50% - 0rem);
        }
        .hrpCol-9-desk {
            flex: 56.25%;
            max-width: calc(56.25% - 0rem);
        }
        .hrpCol-10-desk {
            flex: 62.5%;
            max-width: calc(62.5% - 0rem);
        }
        .hrpCol-11-desk {
            flex: 68.75%;
            max-width: calc(68.75% - 0rem);
        }
        .hrpCol-12-desk {
            flex: 75%;
            max-width: calc(75% - 0rem);
        }
        .hrpCol-13-desk {
            flex: 81.25%;
            max-width: calc(81.25% - 0rem);
        }
        .hrpCol-14-desk {
            flex: 87.5%;
            max-width: calc(87.5% - 0rem);
        }
        .hrpCol-15-desk {
            flex: 93.75%;
            max-width: calc(93.75% - 0rem);
        }
        .hrpCol-16-desk {
            flex: 100%;
            max-width: calc(100% - 0rem);
        }
    }
    @media only screen and (min-width: $brk-desk-large) {
        .hrpCol-skip-1-desklg { margin-left: 3.125%; }
        .hrpCol-skip-2-desklg { margin-left: 6.25%; }
        .hrpCol-skip-3-desklg { margin-left: 9.375%; }
        .hrpCol-skip-4-desklg { margin-left: 12.5%; }
        .hrpCol-skip-5-desklg { margin-left: 15.625%; }
        .hrpCol-skip-6-desklg { margin-left: 18.75%; }
        .hrpCol-skip-7-desklg { margin-left: 21.875%; }
        .hrpCol-skip-8-desklg { margin-left: 25%; }
        .hrpCol-skip-9-desklg { margin-left: 28.125%; }
        .hrpCol-skip-10-desklg { margin-left: 31.25%; }
        .hrpCol-skip-11-desklg { margin-left: 34.375%; }
        .hrpCol-skip-12-desklg { margin-left: 37.5%; }
        .hrpCol-skip-13-desklg { margin-left: 40.625%; }
        .hrpCol-skip-14-desklg { margin-left: 43.75%; }
        .hrpCol-skip-15-desklg { margin-left: 46.875%; }
        .hrpCol-skip-16-desklg { margin-left: 50%; }
        .hrpCol-skip-17-desklg { margin-left: 53.125%; }
        .hrpCol-skip-18-desklg { margin-left: 56.25%; }
        .hrpCol-skip-19-desklg { margin-left: 59.375%; }
        .hrpCol-skip-20-desklg { margin-left: 62.5%; }
        .hrpCol-skip-21-desklg { margin-left: 65.625%; }
        .hrpCol-skip-22-desklg { margin-left: 68.75%; }
        .hrpCol-skip-23-desklg { margin-left: 71.875%; }
        .hrpCol-skip-24-desklg { margin-left: 75%; }
        .hrpCol-skip-25-desklg { margin-left: 78.125%; }
        .hrpCol-skip-26-desklg { margin-left: 81.25%; }
        .hrpCol-skip-27-desklg { margin-left: 84.375%; }
        .hrpCol-skip-28-desklg { margin-left: 87.5%; }
        .hrpCol-skip-29-desklg { margin-left: 90.625%; }
        .hrpCol-skip-30-desklg { margin-left: 93.75%; }
        .hrpCol-skip-31-desklg { margin-left: 96.875%; }

        .hrpCol-0-desklg {
            display: none;
            flex: 0%;
            max-width: calc(0% - 0rem);
        }

        .hrpCol-1-desklg { 
            flex: 3.125%;
            max-width: calc(3.125% - 0rem);
        }
        .hrpCol-2-desklg { 
            flex: 6.25%;
            max-width: calc(6.25% - 0rem);
        }
        .hrpCol-3-desklg { 
            flex: 9.375%;
            max-width: calc(9.375% - 0rem);
        }
        .hrpCol-4-desklg { 
            flex: 12.5%;
            max-width: calc(12.5% - 0rem);
        }
        .hrpCol-5-desklg { 
            flex: 15.625%;
            max-width: calc(15.625% - 0rem);
        }
        .hrpCol-6-desklg { 
            flex: 18.75%;
            max-width: calc(18.75% - 0rem);
        }
        .hrpCol-7-desklg { 
            flex: 21.875%;
            max-width: calc(21.875% - 0rem);
        }
        .hrpCol-8-desklg { 
            flex: 25%;
            max-width: calc(25% - 0rem);
        }
        .hrpCol-9-desklg { 
            flex: 28.125%;
            max-width: calc(28.125% - 0rem);
        }
        .hrpCol-10-desklg { 
            flex: 31.25%;
            max-width: calc(31.25% - 0rem);
        }
        .hrpCol-11-desklg { 
            flex: 34.375%;
            max-width: calc(34.375% - 0rem);
        }
        .hrpCol-12-desklg { 
            flex: 37.5%;
            max-width: calc(37.5% - 0rem);
        }
        .hrpCol-13-desklg { 
            flex: 40.625%;
            max-width: calc(40.625% - 0rem);
        }
        .hrpCol-14-desklg { 
            flex: 43.75%;
            max-width: calc(43.75% - 0rem);
        }
        .hrpCol-15-desklg { 
            flex: 46.875%;
            max-width: calc(46.875% - 0rem);
        }
        .hrpCol-16-desklg { 
            flex: 50%;
            max-width: calc(50% - 0rem);
        }
        .hrpCol-17-desklg { 
            flex: 53.125%;
            max-width: calc(53.125% - 0rem);
        }
        .hrpCol-18-desklg { 
            flex: 56.25%;
            max-width: calc(56.25% - 0rem);
        }
        .hrpCol-19-desklg { 
            flex: 59.375%;
            max-width: calc(59.375% - 0rem);
        }
        .hrpCol-20-desklg { 
            flex: 62.5%;
            max-width: calc(62.5% - 0rem);
        }
        .hrpCol-21-desklg { 
            flex: 65.625%;
            max-width: calc(65.625% - 0rem);
        }
        .hrpCol-22-desklg { 
            flex: 68.75%;
            max-width: calc(68.75% - 0rem);
        }
        .hrpCol-23-desklg { 
            flex: 71.875%;
            max-width: calc(71.875% - 0rem);
        }
        .hrpCol-24-desklg { 
            flex: 75%;
            max-width: calc(75% - 0rem);
        }
        .hrpCol-25-desklg { 
            flex: 78.125%;
            max-width: calc(78.125% - 0rem);
        }
        .hrpCol-26-desklg { 
            flex: 81.25%;
            max-width: calc(81.25% - 0rem);
        }
        .hrpCol-27-desklg { 
            flex: 84.375%;
            max-width: calc(84.375% - 0rem);
        }
        .hrpCol-28-desklg { 
            flex: 87.5%;
            max-width: calc(87.5% - 0rem);
        }
        .hrpCol-29-desklg { 
            flex: 90.625%;
            max-width: calc(90.625% - 0rem);
        }
        .hrpCol-30-desklg { 
            flex: 93.75%;
            max-width: calc(93.75% - 0rem);
        }
        .hrpCol-31-desklg { 
            flex: 96.875%;
            max-width: calc(96.875% - 0rem);
        }
        .hrpCol-32-desklg { 
            flex: 100%;
            max-width: calc(100% - 0rem);
        }
    }
}

.MuiStepper-root.MuiPaper-root {
    background-color: transparent;
}
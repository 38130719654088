html, body {
  margin: 0;
  color: #161616;
  font-family: Roboto,sans-serif;
  background-color: #E8EBED;
}

div#root {
    position: relative;
    min-height: 100vh;

    .pageMain, .pageHome {
        padding-bottom: 2rem;
        // padding-left: 1rem;    
    }

    .pageHome {
        background-color: #ffffff;
    }

    .pageDefault {
        background-color: #f4f4f4;
    }

    footer {
        border-top: solid #999 1px;
    }
}

div#root .MuiButton-root {
    text-transform: none;
}

.hrpcb {
    main {
        font-family: "Montserrat";

        h1 {
            font-size: 25px;
            font-weight: 500;
        }

        p {
            font-size: 20px;
            font-weight: 300;
        }
    }
}
@import "../../../../style/common.scss";

.newsHead {
    padding: 0rem;
    background-color: #dcdcdc;
}

.newsBody {
    padding: .5rem 1rem 1rem 1rem;
    border: solid #dcdcdc 1px;
    background-color: #ffffff;
    @media only screen and (min-width: $brk-tablet) {
        border: solid #dcdcdc 0px;
        background-color: #f4f4f4;
    }
    .newsDate {
        float: right;
        text-align: right;
        font-size: .8rem;
        color: #666;

        @media only screen and (min-width: $brk-tablet) {
            float: none;
            text-align: left;
        }
        @media only screen and (min-width: $brk-desk) {
            float: right;
            text-align: right;
        }
    }
}

.MuiAccordionSummary-content {
    .MuiIconButton-root {
        padding: 0px;
    }
}

@use '@carbon/themes/scss/themes' as *;
@use '@carbon/themes';
@use '@carbon/themes/scss/utilities';
.login {
    margin-top: 2rem;
    .cds--btn {
        min-width: 100%;

        &.Google {
            background: #df4a32;
            color: white;
            
            &:hover {
                background: #c4422c
            }
        }
        &.Facebook {
            background: #4267b2;
            color: white;
            
            &:hover {
                background: #3a5a9d
            }
        }
    }

    text-align:center;

    > div {

        > div, hr {
            margin-top: 16px;
            margin-bottom: 16px;
        }

        hr {
            position: relative;
            top: 14px;
        }
        hr + div {
            position: relative;
            top: -14px;
        }
    }
}
@import "./style/common.scss";

.a11y {
    position: fixed;
    top: -1000;
    left: -1000;
    width: 0px;
    overflow: hidden;
}

.homeroomParent h2 {
    font-size: 1.25rem;
}
.themeDefault {
    --theme-color: rgb(232, 235, 237);
    min-height: 100vh;
}

.homeroomParent {
    --theme-color: #e8ebed;
    background-color: var(--theme-color);
}

.hrpcb {
    min-height: calc(100vh - 4rem);
    &.themeContent {
        h2 {
            display: block;
            margin-top: 1.5rem;
        }
        p {
            margin-top: 1rem;
        }
        .mdContent {
            min-height: calc(100vh - 13rem);
        }
    }
}
ul {
    padding-left: 1rem; 
}

.linkPanel {
    li {
        font-size: .8rem;
    }
}

.mobileHome {
    .homeIconButton {
        color: #f6f4f1;
        &:hover {
            color: #ffffff;
        }
    }

    > .MuiAccordion-root {
        > .MuiAccordionSummary-root {
            background-color: #2F5043;
            color: white;
            .MuiIconButton-label {
                color: white;
            }
        }
    }
    .MuiAccordionDetails-root {
        background-color: #f4f4f4;
        .MuiAccordionSummary-root {
            background-color: white;
        }
    }
}

h2 {
    font-size: 1.25rem;
    display: inline;
}

.MuiAccordionSummary-content h2 {
    margin: 0px;
}

